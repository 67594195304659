@import "../../style/mixins";

header {
  background-color: #ffffff;
  width: 100%;
  height: 70px;
  position: fixed;
  top:0;
  left: 0;
  z-index: 100;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .logo {
    height: auto;
    img {
      width: 165px;
    }
  }
  .burger {
    height: 100%;
    display: none;
    flex-shrink: 0;
    align-items: center;
    width: 24px;
    .image {
      width: 24px;
      height: 16px;
      img {
        margin: 0 auto;
        width: auto;
        //height: 16px;
      }
    }
  }
  .menu {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    .logo {
      flex-grow: 0;
      width: auto;
      margin-right: 56px;
    }
  }
  .menu-list {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    .menu-item {
      height: 100%;
      color: #4a4a4a;
      display: flex;
      align-items: center;
      &:not(:last-of-type){
        margin-right: 24px;
      }
      &.active {
        &:after {
          content: "";
          height: 4px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: #00AAD2;
        }
      }
    }
  }
  .image.top-button {
    margin-right: 32px;
  }
  &[dir="rtl"]{
    .menu {;
      .logo {
        margin-right: 0;
        margin-left: 56px;
      }
    }
    .menu-list {
      .menu-item {
        &:not(:last-of-type){
          margin-right: 0;
          margin-left: 24px;
        }
      }
    }
    .image.top-button {
      margin-right: 0;
      margin-left: 32px;
    }
  }
}

@include break-point(mobile) {
  header {
    height: 60px;
    .burger{

      display: flex;
    }
    .logo img{
      width: 132px;
    }
    .image.top-button {
      display: none;
    }
    .menu {
      .logo {
        margin-right: 16px;
      }
    }
    .menu-list {
      padding-right: 8px;
      .menu-item {
        height: 100%;
        font-size: 12px;
        &:not(:last-of-type){
          margin-right: 16px;
        }
        &.active {
          &:after {
            content: "";
            height: 4px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #00AAD2;
          }
        }
      }
    }
    &[dir="rtl"]{
      .menu {
        .logo {
          margin-right: 0;
          margin-left: 16px;
        }
      }
      .menu-list {
        padding-left: 8px;
        padding-right: 0;
      }
    }
  }
}

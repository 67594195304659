@import "../../style/mixins";

.pp-section-interior {
  padding-top: 144px;

  .slider {
    margin-top: 48px;
  }

  .image-scroll {
    position: absolute;
    top: 0;
    transform: translateY(-50%);

    .image {
      height: 100px;
    }
  }
}


@include break-point(mobile) {
  .pp-section-interior {
    .slider {
      margin-top: 40px;
    }
  }
}

@import "../../style/mixins";

footer {
  background-color: #032D5D;

  .logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 810px;
    margin: 0 auto;

    .rivenditore {
      //width: 200px;
      height: auto;
      max-width: 200px;
      &.jeddah, &.kuwait {
        max-width: 500px;
        width: 400px;
      }
    }

    .logo {
      width: 186px;
      height: auto;
    }
  }

  .dealer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

  }

  .text {
    @include text-small;
    color: #ffffff;
    text-align: center;
    max-width: 830px;
    margin: 24px auto 0;
    direction: ltr;
  }
}


@include break-point(mobile) {
  footer {
    .dealer {
      margin-top: 24px;
    }
    .logos {
      flex-direction: column-reverse;

      .image {
        margin-top: 32px;

        &:last-child {
          margin-top: 0;
        }
      }
    }
  }
}

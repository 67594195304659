@import "../../../style/mixins";

.title-block {
  max-width: 870px;

  .title {
    @include title-h1;

    span {
      color: #00AAD2;
    }
  }

  .btn-icon {
    margin-top: 24px;
  }

  .subtitle {
    @include text-subtitle;
    margin: 16px auto 0;
    max-width: 760px;
  }

  &.white {
    .title,
    .subtitle {
      color: #ffffff;
    }
  }

  &.center {
    text-align: center;
    margin: 0 auto;
  }
}
.centered .title-block {
  margin: 0 auto;
  text-align: center;
}

@include break-point(mobile) {

  .title-block {

    .subtitle {
      margin-top: 8px;
    }

    .btn-icon {
      margin-top: 16px;
    }
  }

}

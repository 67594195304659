@import "./src/style/mixins";

.pp-section-configurator {
  .configurator {
    margin-top: 48px;
    .toggle {
      justify-content: flex-start;
      margin: 24px 0 40px;
    }
  }
  .main-content {
    margin: 48px 0 24px;
  }
}
@import "../../style/mixins";

.safe-care {

  &.rtl {
    .container {
      .content {
        &_bottom {
          .text {
            .title-block {
              .title {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  &.ltr {
    .container {
      .slider {
        &-content {
          .swiper-container {
            .controls {
              .control-arrow {

                transform: rotate(0);
                margin: 0 -25px;

              }
            }
          }
        }
      }
    }
  }

  .container {

    .slider {
      padding-bottom: 54px;

      &-content {
        .swiper-container {
          .control-arrow {

            transform: rotate(180deg);
            margin: 0 -25px;

          }
        }
      }


    }

    .swiper-slide {

      .icon {
        margin: 12px auto;
        width: 66px;
        height: 66px;
      }

      .slide {

        &_video {
          width: 100%;
          height: auto;

          .btn-icon {
            @include positionCC;
            z-index: 100;
          }
        }

        &_text {
          text-align: center;

          .title-block {

            .title {
              @include text-simple;
              font-size: 18px;
            }
          }
          .slide_subtitle {
            padding: 0 50px;
            @include text-small;
            font-size: 12px;
          }
        }
      }
    }
  }

  .title-block {
    margin: 0 auto 40px;
    width: 80%;
  }

  .content {

    &_top {
      margin: 0 auto;
      width: 100%;
      max-width: 800px;
      padding-bottom: 40px;

      .bar {
        height: 5px;
        background: #00AAD2;
        transition: 0.3s;
      }

      &_icon {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        .item {
          width: 33%;
          cursor: pointer;

          div {
            margin: 0 auto;
            text-align: center;
          }

          &:hover > svg > path {
            transition: 0.2s;
            fill: black;
          }
        }

        svg {
          width: 85px;
          height: 85px;
          margin: 0 auto 10px;
          transition: 0.2s;

        }

        .active > * {
          fill: black;
        }
      }
    }

    &_bottom {
      display: flex;
      justify-content: space-between;

      &.hide {
        display: none;
      }

      &.active {
        display: flex;
      }



      .video {
        cursor: pointer;
        width: 63%;

        .btn-icon {
          @include positionCC;
          z-index: 100;
        }
      }

      .text {
        width: 30%;
        border-top: 1px solid #D8D8D8;

        .title-block {
          width: 100%;
        }

        .title {
          width: 100%;
          padding-top: 40px;
          margin-bottom: 12px;
          @include title-h3;
          color: #4a4a4a;
          font-weight: 500;
          text-align: left;
        }

        .subtitle {
          @include text-simple;
          color: #4a4a4a;
        }

      }

    }
  }
}